.offerTile {
  width: 100%;
  min-height: 20px;
  margin-bottom: 30px;
  a,
  .oferTile__unwrap {
    font-weight: bold;
    font-size: 20px;
    color: #ac7b09;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: none;
  }

  ul li::before {
    content: "\2022";
    color: #ecc058;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.oferTile__title {
  display: flex;
  min-height: 55px;
}
.oferTile__icon {
  background-color: #ecc058;
  padding: 10px;
  height: 60px;
  width: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.oferTile__name {
  display: flex;
  align-items: center;
  width: calc(100% - 55px);
  padding-left: 15px;
  font-size: 24px;
  border-bottom: 3px solid #ecc058;
}
.oferTile__content {
  margin-left: 57px;
  padding: 15px;
  font-size: 18px;
  border-left: 3px solid #ecc058;
  overflow: hidden;
  position: relative;
}
.oferTile__unwrap {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
}
.offerTile--wrapped {
  .oferTile__content {
    max-height: 160px;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 60px;
      left: 0px;
      bottom: 0px;
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 90%
      );
    }
  }
}
@media (max-width: 576px) {
  .offerTile {
    .oferTile__content {
      margin-left: 0px;
    }
  }
}
