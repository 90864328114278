.styledButton,
input[type="submit"] {
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #e4b13a;
  font-size: 22px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: 0.1s ease-in background-color;
  &:hover {
    background-color: hsl(42, 73%, 50%);
  }
}
