.contact {
  width: 100%;
}
.contact__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px auto 40px auto;
  max-width: 1100px;
  padding: 0 10px;
}

.contact__options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-left: 4px solid #ffca4f;
  .contact__contactOption {
    max-width: 50%;
    padding: 20px;
    span {
      display: block;
    }
  }
  .contact__cta {
    max-width: 400px;
    .contact__ctaText {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  .b_name {
    font-size: 34px;
    margin-bottom: 15px;
  }
  .b_tel {
    margin-top: 10px;
  }
  .b_address,
  .b_tel,
  .b_email {
    font-size: 22px;
  }
}
.contact__map {
  border: 0;
  margin-top: 40px;
  margin-bottom: 50px;

  width: 100%;
}

@media (max-width: 768px) {
  .contact__options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contact__options {
    border: none;
    .contact__contactOption {
      max-width: 100%;
      &:first-child {
        width: 100%;
      }
    }
  }
}
@media (max-width: 576px) {
  .pricing__form {
    padding: 0px !important;
  }
}
