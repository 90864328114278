.crewCard {
  width: 350px;
  margin: 15px;
  position: relative;
  .crewCard__photoWrapper {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    border: 2px solid #444;
    width: 240px;
    height: 240px;
    padding: 15px;
  }
  .crewCard__photo {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .crewCard__content {
    padding-top: 150px;
  }
  .crewCard__details {
    background-color: white;
    width: 100%;
    padding: 110px 10px 25px 10px;
    text-align: center;
    min-height: 230px;
    box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
    .crewCard__name {
      font-size: 25px;
      padding-bottom: 10px;
      width: 100%;
      font-weight: bold;
    }
    .crewCard__positionName {
      font-size: 20px;
      color: #333;
    }
  }
}
