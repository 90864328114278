.hamburgerMenu {
  width: 100%;
  padding: 12px 20px;
  .hamburgerMenu__mainNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hamburgerMenu__logo {
    height: 55px;
    img {
      object-fit: contain;
      height: 100%;
    }
  }
  .hamburgerMenu__hamburgerIcon {
    margin-right: 10px;
  }
}
.hamburgerMenu__nav {
  font-size: 22px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 15px;
  nav {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 0 auto;
  }
  .option {
    margin: 10px 0;
  }
  .option--active {
    border: 3px solid #ecc058;
    border-style: none solid;
  }
}
