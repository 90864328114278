.aboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // border-top: 2px solid #e4b13a;
  margin-bottom: 50px;
}

.aboutSection__content {
  max-width: 1000px;
  padding: 20px;
  font-size: 18px;
  line-height: 30px;
}
.aboutSection__attributes {
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px 0;
}
.aboutSection__photo {
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  height: 300px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.aboutSection__attributeIcon {
  margin: 10px 50px;
  width: 100px;
  height: 100px;
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #ecc15d;
    transform: rotate(45deg);
  }
}
.aboutSection__attributeName {
  margin-top: 30px;
  text-align: center;
  font-size: 22px;
  color: #333;
  font-weight: bold;
}

@media (max-width: 992px) {
  .aboutSection__attribute {
    .aboutSection__attributeIcon {
      width: 85px;
      height: 85px;
      .icon {
        transform: scale(0.7);
      }
    }
  }
}

@media (max-width: 768px) {
  .aboutSection__attributes {
    flex-wrap: wrap;
  }
  .aboutSection__attribute {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 34%;
  }

  .aboutSection__attributeIcon {
    margin: 40px 50px;
  }
  .aboutSection__attributeName {
    margin-top: 0px;
  }
}
@media (max-width: 576px) {
  .aboutSection__attribute {
    width: 50%;
  }

  .aboutSection__attribute {
    .aboutSection__attributeIcon {
      width: 75px;
      height: 75px;
      .icon {
        transform: scale(0.65);
      }
    }
  }

  .aboutSection__attributeIcon {
    margin: 40px 50px;
  }
  .aboutSection__attributeName {
    margin-top: 0px;
  }
}
