.offer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 2px solid #e4b13a;
  padding-bottom: 30px;
}
.offer__tiles {
  max-width: 1000px;
  width: 100%;
  padding: 0 30px;
}

.offer__callToActionWrapper {
  margin-top: 30px;
  width: 100%;
  padding: 40px 10px;
  background-color: rgb(255, 244, 225);
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
}
.offer__callToActionText {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
  color: rgb(65, 65, 65);
}
.offer__callToAction {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

@media (max-width: 576px) {
  .offer {
    .offer__tiles {
      padding: 0 15px;
    }
    .offer__callToActionText {
      font-size: 18px;
    }
  }
}
