.about {
  width: 100%;
}
.about__content {
  padding: 50px 10px;
  background-color: rgb(255, 233, 196);
}

.about__crew {
  margin: 0 auto;
  display: flex;
  max-width: 1100px;
  flex-wrap: wrap;
  flex-direction: column;
  .abour__row {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }
}
