footer {
  background-color: #444;
  border-top: 2px solid #c9a347;
  color: #c9a347;
  padding: 20px;
}
.footer__content {
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.footer__leftPart {
  padding-right: 15px;
  display: flex;
  font-size: 18px;
}
.footer__rightPart {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.author {
  font-size: 19px;
  a:hover {
    text-decoration: underline;
  }
}
.footer__contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
}
.footer__logo {
  height: 110px;
  img {
    height: 100%;
    object-fit: contain;
  }
}
.footer__nav {
  display: flex;
  margin-left: 40px;
  align-items: flex-start;
  .option {
    margin: 0 10px;
    font-size: 22px;
    border-bottom: 2px solid transparent;
    &:hover {
      border-color: #c9a347;
    }
  }
}

@media (max-width: 992px) {
  footer {
    padding: 20px 10px;
    .footer__logo {
      height: 80px;
    }
    .footer__nav {
      .option {
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 768px) {
  footer {
    .footer__nav {
      display: none;
    }
    .footer__rightPart {
      justify-content: flex-end;
    }
  }
}
@media (max-width: 576px) {
  footer {
    padding-bottom: 10px;
    .footer__content {
      flex-direction: column;
    }
    .footer__nav {
      display: none;
    }
    .footer__address,
    .footer__phoneAndMail {
      font-size: 14px;
    }
    .footer__leftPart {
      width: 100%;
      justify-content: space-between;
    }
    .footer__rightPart {
      margin-top: 20px;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
    .author {
      font-size: 18px;
    }
  }
}
