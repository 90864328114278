.pricing {
  width: 100%;
  a {
    font-weight: bold;
    color: #ac7b09;
    &:hover {
      text-decoration: underline;
    }
  }
}
.pricing__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px auto 40px auto;
  max-width: 1100px;
  padding: 0 10px;
  h2 {
    margin: 10px 0;
  }
  h3 {
    margin: 0px;
  }
}
.pricing__form {
  margin-top: 20px;
  border-top: 4px solid #ffca4f;
  padding: 20px;
  width: 100%;
  max-width: 800px;
}
.formField {
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 5px 10px;
  border-left: 4px solid #ffca4f;
  width: 100%;

  .formField__name {
    font-size: 20px;
    padding-bottom: 10px;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    width: 100%;
    font-size: 22px;
    padding: 5px;
  }
}
.formField__additionalText {
  padding-left: 15px;
  font-size: 20px;
  margin-top: 15px;
  input {
    margin-top: 10px;
  }
}
.formField__selectableWrapper {
  font-size: 20px;
  padding-left: 15px;
  margin: 8px 0;
  label {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
  }
  input[type="checkbox"],
  input[type="radio"] {
    margin-right: 10px;
    transform: scale(1.4);
  }
}
.terms {
  padding: 10px;
}
.submit {
  margin-top: 20px;
}
.submit--disabled {
  input {
    pointer-events: none;
    background-color: grey;
  }
}
textarea {
  width: 100%;
  min-height: 150px;
  resize: none;
}
.req {
  color: red;
  font-size: 29px;
  line-height: 0px;
}
