@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
svg {
  width: auto;
}

.borderBar {
  background-color: rgb(255, 233, 196);
  width: 100%;
  height: 55px;
  margin-bottom: 20px;
}
.sectionTitle {
  height: 14vh;
  display: flex;
  align-items: center;
  .sectionTitleText {
    padding: 3px 5px;
    font-size: 30px;
    color: #333;
    border-bottom: 4px solid #444;
  }
}

@media (max-width: 992px) {
  .sectionTitle {
    height: 100px;
  }
}
