.mainSection {
  min-height: calc(87vh - 120px - 40px);
  padding: 30px 0;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  img {
    left: 0px;
    top: 0px;
    position: absolute;
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  h1 {
    font-size: 32px;
  }
  .mainSection__contentWrapper {
    max-width: 500px;
    width: 100%;
    min-height: 460px;
    margin-left: 100px;
    border: 4px solid #c9a347;
    background-color: #fff;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);
    color: black;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  h1 {
    margin: 10px 0;
  }

  .mainSection__title {
    border-bottom: 1px solid #c9a347;
  }

  .mainSection__details {
    padding: 15px 0;
    font-size: 25px;
  }
  .mainSection__quickPreviewWrapper {
    padding-bottom: 13px;
  }
  .mainSection__quickPreview {
    border-top: 1px solid #c9a347;
    font-size: 20px;
    margin-bottom: 25px;
    padding-top: 5px;
    h3 {
      margin: 15px 0;
    }
  }
}

.phoneContact {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-weight: bold;
  font-size: 30px;
}
.phoneContact__details {
  margin-left: 10px;
}

@media (max-width: 992px) {
  .mainSection {
    min-height: auto;
    padding: 40px 0;
    h1 {
      font-size: 28px;
    }
    .mainSection__contentWrapper {
      min-height: 400px;
      margin-left: 50px;
    }
    .mainSection__details {
      font-size: 20px;
    }
    .mainSection__quickPreview {
      font-size: 16px;
    }
  }
  .phoneContact {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .mainSection {
    h1 {
      font-size: 26px;
    }
    .mainSection__contentWrapper {
      margin-left: 20px;
    }
  }
}

@media (max-width: 576px) {
  .mainSection {
    .mainSection__contentWrapper {
      width: 100%;
      margin: 0 20px;
      max-width: 100%;
      .button {
        font-size: 10px;
      }
    }
  }
}
