.quickContact {
  background-color: #191919;
  color: #c9a347;
  height: 40px;
  width: 100%;
  padding-right: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  .quickContact__item {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .quickContact__details {
    margin-left: 8px;
  }
}

@media (max-width: 992px) {
  .quickContact {
    padding-right: 60px;
  }
}
@media (max-width: 576px) {
  .quickContact {
    padding: 0px;
    font-size: 14px;
    position: relative;
    justify-content: flex-start;
  }
}
