@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
body{margin:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-family:"Lato", sans-serif}*,*::after,*::before{box-sizing:border-box}a{text-decoration:none;color:inherit}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}svg{width:auto}.borderBar{background-color:#ffe9c4;width:100%;height:55px;margin-bottom:20px}.sectionTitle{height:14vh;display:flex;align-items:center}.sectionTitle .sectionTitleText{padding:3px 5px;font-size:30px;color:#333;border-bottom:4px solid #444}@media (max-width: 992px){.sectionTitle{height:100px}}


.mobileNav{display:none}.desktopNav{height:110px;width:100%;color:#c9a347;background-color:white;padding:15px 100px;display:flex;align-items:center;justify-content:space-between;font-size:22px}.desktopNav a{display:block;height:100%}.desktopNav .logo{height:100%}.desktopNav .logo img{height:100%}.desktopNav nav{display:flex}.desktopNav nav a{margin:12px}.desktopNav nav .option{padding:2px 7px;position:relative}.desktopNav nav .option::after{content:"";position:absolute;left:0px;bottom:-5px;height:3px;width:100%;background-color:#c9a347;transform-origin:top;transform:scaleY(0);transition:0.1s ease transform}.desktopNav nav .option:hover::after{transform:scaleY(0.5)}.desktopNav nav .option--active::after{transform:scaleY(1) !important}@media (max-width: 1400px){.desktopNav{height:100px}}@media (max-width: 1200px){.desktopNav{padding:20px 40px}}@media (max-width: 992px){.desktopNav{height:95px;font-size:22px}}@media (max-width: 768px){.desktopNav{height:85px;padding:10px 15px;font-size:20px}.desktopNav nav a{margin:5px}}@media (max-width: 576px){.desktopNav{display:none}.mobileNav{display:block}}

.quickContact{background-color:#191919;color:#c9a347;height:40px;width:100%;padding-right:120px;display:flex;justify-content:flex-end;align-items:center;font-size:18px;font-weight:bold}.quickContact .quickContact__item{display:flex;align-items:center;margin-left:20px}.quickContact .quickContact__details{margin-left:8px}@media (max-width: 992px){.quickContact{padding-right:60px}}@media (max-width: 576px){.quickContact{padding:0px;font-size:14px;position:relative;justify-content:flex-start}}

.hamburgerMenu{width:100%;padding:12px 20px}.hamburgerMenu .hamburgerMenu__mainNav{display:flex;justify-content:space-between;align-items:center}.hamburgerMenu .hamburgerMenu__logo{height:55px}.hamburgerMenu .hamburgerMenu__logo img{object-fit:contain;height:100%}.hamburgerMenu .hamburgerMenu__hamburgerIcon{margin-right:10px}.hamburgerMenu__nav{font-size:22px;letter-spacing:1px;text-align:center;margin-top:15px}.hamburgerMenu__nav nav{display:flex;flex-direction:column;width:200px;margin:0 auto}.hamburgerMenu__nav .option{margin:10px 0}.hamburgerMenu__nav .option--active{border:3px solid #ecc058;border-style:none solid}

.styledButton,input[type="submit"]{padding:10px 15px;width:100%;text-align:center;color:white;background-color:#e4b13a;font-size:22px;font-weight:bold;border:none;cursor:pointer;transition:0.1s ease-in background-color}.styledButton:hover,input[type="submit"]:hover{background-color:#dda522}

.mainSection{min-height:calc(87vh - 120px - 40px);padding:30px 0;width:100%;position:relative;display:flex;align-items:center}.mainSection img{left:0px;top:0px;position:absolute;z-index:-1;object-fit:cover;width:100%;height:100%}.mainSection h1{font-size:32px}.mainSection .mainSection__contentWrapper{max-width:500px;width:100%;min-height:460px;margin-left:100px;border:4px solid #c9a347;background-color:#fff;box-shadow:0 0 2px 1px rgba(0,0,0,0.3);color:black;padding:15px 25px;display:flex;flex-direction:column;justify-content:space-between}.mainSection h1{margin:10px 0}.mainSection .mainSection__title{border-bottom:1px solid #c9a347}.mainSection .mainSection__details{padding:15px 0;font-size:25px}.mainSection .mainSection__quickPreviewWrapper{padding-bottom:13px}.mainSection .mainSection__quickPreview{border-top:1px solid #c9a347;font-size:20px;margin-bottom:25px;padding-top:5px}.mainSection .mainSection__quickPreview h3{margin:15px 0}.phoneContact{display:flex;align-items:center;margin-top:15px;font-weight:bold;font-size:30px}.phoneContact__details{margin-left:10px}@media (max-width: 992px){.mainSection{min-height:auto;padding:40px 0}.mainSection h1{font-size:28px}.mainSection .mainSection__contentWrapper{min-height:400px;margin-left:50px}.mainSection .mainSection__details{font-size:20px}.mainSection .mainSection__quickPreview{font-size:16px}.phoneContact{font-size:20px}}@media (max-width: 768px){.mainSection h1{font-size:26px}.mainSection .mainSection__contentWrapper{margin-left:20px}}@media (max-width: 576px){.mainSection .mainSection__contentWrapper{width:100%;margin:0 20px;max-width:100%}.mainSection .mainSection__contentWrapper .button{font-size:10px}}

.offerTile{width:100%;min-height:20px;margin-bottom:30px}.offerTile a,.offerTile .oferTile__unwrap{font-weight:bold;font-size:20px;color:#ac7b09}.offerTile a:hover,.offerTile .oferTile__unwrap:hover{text-decoration:underline}.offerTile ul{list-style:none}.offerTile ul li::before{content:"\2022";color:#ecc058;font-weight:bold;display:inline-block;width:1em;margin-left:-1em}.oferTile__title{display:flex;min-height:55px}.oferTile__icon{background-color:#ecc058;padding:10px;height:60px;width:60px;color:white;display:flex;justify-content:center;align-items:center}.oferTile__name{display:flex;align-items:center;width:calc(100% - 55px);padding-left:15px;font-size:24px;border-bottom:3px solid #ecc058}.oferTile__content{margin-left:57px;padding:15px;font-size:18px;border-left:3px solid #ecc058;overflow:hidden;position:relative}.oferTile__unwrap{margin-top:10px;width:100%;text-align:center;font-size:18px;padding:5px;cursor:pointer}.offerTile--wrapped .oferTile__content{max-height:160px}.offerTile--wrapped .oferTile__content::before{content:"";position:absolute;width:100%;height:60px;left:0px;bottom:0px;background-image:linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 90%)}@media (max-width: 576px){.offerTile .oferTile__content{margin-left:0px}}

.offer{width:100%;display:flex;align-items:center;flex-direction:column;border-top:2px solid #e4b13a;padding-bottom:30px}.offer__tiles{max-width:1000px;width:100%;padding:0 30px}.offer__callToActionWrapper{margin-top:30px;width:100%;padding:40px 10px;background-color:#fff4e1;box-shadow:0 0 2px 0px rgba(0,0,0,0.2)}.offer__callToActionText{text-align:center;font-size:24px;font-weight:bold;padding-bottom:20px;color:#414141}.offer__callToAction{width:100%;max-width:400px;margin:0 auto}@media (max-width: 576px){.offer .offer__tiles{padding:0 15px}.offer .offer__callToActionText{font-size:18px}}

.aboutSection{display:flex;justify-content:center;align-items:center;flex-direction:column;margin-bottom:50px}.aboutSection__content{max-width:1000px;padding:20px;font-size:18px;line-height:30px}.aboutSection__attributes{overflow:hidden;display:flex;justify-content:center;margin-top:20px;padding:20px 0}.aboutSection__photo{max-width:1000px;margin:0 auto;width:100%;height:300px;box-shadow:0 0 2px 1px rgba(0,0,0,0.3)}.aboutSection__photo img{width:100%;height:100%;object-fit:cover}.aboutSection__attributeIcon{margin:10px 50px;width:100px;height:100px;position:relative;color:white;display:flex;justify-content:center;align-items:center}.aboutSection__attributeIcon::after{content:"";position:absolute;top:0px;left:0px;z-index:-1;width:100%;height:100%;background-color:#ecc15d;transform:rotate(45deg)}.aboutSection__attributeName{margin-top:30px;text-align:center;font-size:22px;color:#333;font-weight:bold}@media (max-width: 992px){.aboutSection__attribute .aboutSection__attributeIcon{width:85px;height:85px}.aboutSection__attribute .aboutSection__attributeIcon .icon{transform:scale(0.7)}}@media (max-width: 768px){.aboutSection__attributes{flex-wrap:wrap}.aboutSection__attribute{display:flex;flex-direction:column;align-items:center;justify-content:center;width:34%}.aboutSection__attributeIcon{margin:40px 50px}.aboutSection__attributeName{margin-top:0px}}@media (max-width: 576px){.aboutSection__attribute{width:50%}.aboutSection__attribute .aboutSection__attributeIcon{width:75px;height:75px}.aboutSection__attribute .aboutSection__attributeIcon .icon{transform:scale(0.65)}.aboutSection__attributeIcon{margin:40px 50px}.aboutSection__attributeName{margin-top:0px}}

footer{background-color:#444;border-top:2px solid #c9a347;color:#c9a347;padding:20px}.footer__content{width:100%;max-width:1100px;display:flex;justify-content:space-between;margin:0 auto}.footer__leftPart{padding-right:15px;display:flex;font-size:18px}.footer__rightPart{padding-left:15px;display:flex;flex-direction:column;align-items:flex-end;justify-content:space-between}.author{font-size:19px}.author a:hover{text-decoration:underline}.footer__contact{display:flex;flex-direction:column;justify-content:space-between;margin-left:15px}.footer__logo{height:110px}.footer__logo img{height:100%;object-fit:contain}.footer__nav{display:flex;margin-left:40px;align-items:flex-start}.footer__nav .option{margin:0 10px;font-size:22px;border-bottom:2px solid transparent}.footer__nav .option:hover{border-color:#c9a347}@media (max-width: 992px){footer{padding:20px 10px}footer .footer__logo{height:80px}footer .footer__nav .option{font-size:18px}}@media (max-width: 768px){footer .footer__nav{display:none}footer .footer__rightPart{justify-content:flex-end}}@media (max-width: 576px){footer{padding-bottom:10px}footer .footer__content{flex-direction:column}footer .footer__nav{display:none}footer .footer__address,footer .footer__phoneAndMail{font-size:14px}footer .footer__leftPart{width:100%;justify-content:space-between}footer .footer__rightPart{margin-top:20px;justify-content:flex-end;align-items:center;width:100%}footer .author{font-size:18px}}

.contact{width:100%}.contact__content{display:flex;flex-direction:column;align-items:center;width:100%;margin:0px auto 40px auto;max-width:1100px;padding:0 10px}.contact__options{display:flex;justify-content:space-between;width:100%;border-left:4px solid #ffca4f}.contact__options .contact__contactOption{max-width:50%;padding:20px}.contact__options .contact__contactOption span{display:block}.contact__options .contact__cta{max-width:400px}.contact__options .contact__cta .contact__ctaText{font-size:24px;margin-bottom:20px;text-align:center}.contact__options .b_name{font-size:34px;margin-bottom:15px}.contact__options .b_tel{margin-top:10px}.contact__options .b_address,.contact__options .b_tel,.contact__options .b_email{font-size:22px}.contact__map{border:0;margin-top:40px;margin-bottom:50px;width:100%}@media (max-width: 768px){.contact__options{display:flex;flex-direction:column;align-items:center;justify-content:center}.contact__options{border:none}.contact__options .contact__contactOption{max-width:100%}.contact__options .contact__contactOption:first-child{width:100%}}@media (max-width: 576px){.pricing__form{padding:0px !important}}

.pricing{width:100%}.pricing a{font-weight:bold;color:#ac7b09}.pricing a:hover{text-decoration:underline}.pricing__content{display:flex;flex-direction:column;align-items:center;width:100%;margin:0px auto 40px auto;max-width:1100px;padding:0 10px}.pricing__content h2{margin:10px 0}.pricing__content h3{margin:0px}.pricing__form{margin-top:20px;border-top:4px solid #ffca4f;padding:20px;width:100%;max-width:800px}.formField{margin-top:15px;margin-bottom:20px;padding:5px 10px;border-left:4px solid #ffca4f;width:100%}.formField .formField__name{font-size:20px;padding-bottom:10px}.formField input[type="text"],.formField input[type="email"],.formField input[type="tel"]{width:100%;font-size:22px;padding:5px}.formField__additionalText{padding-left:15px;font-size:20px;margin-top:15px}.formField__additionalText input{margin-top:10px}.formField__selectableWrapper{font-size:20px;padding-left:15px;margin:8px 0}.formField__selectableWrapper label{display:flex;margin-bottom:5px;align-items:center}.formField__selectableWrapper input[type="checkbox"],.formField__selectableWrapper input[type="radio"]{margin-right:10px;transform:scale(1.4)}.terms{padding:10px}.submit{margin-top:20px}.submit--disabled input{pointer-events:none;background-color:grey}textarea{width:100%;min-height:150px;resize:none}.req{color:red;font-size:29px;line-height:0px}

.about{width:100%}.about__content{padding:50px 10px;background-color:#ffe9c4}.about__crew{margin:0 auto;display:flex;max-width:1100px;flex-wrap:wrap;flex-direction:column}.about__crew .abour__row{width:100%;flex-wrap:wrap;justify-content:center;display:flex}

.crewCard{width:350px;margin:15px;position:relative}.crewCard .crewCard__photoWrapper{position:absolute;left:0px;right:0px;margin:0 auto;border:2px solid #444;width:240px;height:240px;padding:15px}.crewCard .crewCard__photo{box-shadow:0 0 2px 1px rgba(0,0,0,0.3);width:100%;height:100%}.crewCard .crewCard__photo img{width:100%;height:100%;object-fit:cover}.crewCard .crewCard__content{padding-top:150px}.crewCard .crewCard__details{background-color:white;width:100%;padding:110px 10px 25px 10px;text-align:center;min-height:230px;box-shadow:0 0 2px 0px rgba(0,0,0,0.2)}.crewCard .crewCard__details .crewCard__name{font-size:25px;padding-bottom:10px;width:100%;font-weight:bold}.crewCard .crewCard__details .crewCard__positionName{font-size:20px;color:#333}

.privacyPolicy .privacyPolicy__content{padding:10px;max-width:1100px;margin:0 auto}

