.mobileNav {
  display: none;
}
.desktopNav {
  height: 110px;
  width: 100%;
  color: #c9a347;
  background-color: white;
  padding: 15px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  a {
    display: block;
    height: 100%;
  }
  .logo {
    height: 100%;
    img {
      height: 100%;
    }
  }
  nav {
    display: flex;
    a {
      margin: 12px;
    }
    .option {
      padding: 2px 7px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0px;
        bottom: -5px;
        height: 3px;
        width: 100%;
        background-color: #c9a347;
        transform-origin: top;
        transform: scaleY(0);

        transition: 0.1s ease transform;
      }
      &:hover {
        &::after {
          transform: scaleY(0.5);
        }
      }
    }
    .option--active {
      &::after {
        transform: scaleY(1) !important;
      }
    }
  }
}
@media (max-width: 1400px) {
  .desktopNav {
    height: 100px;
  }
}
@media (max-width: 1200px) {
  .desktopNav {
    padding: 20px 40px;
  }
}
@media (max-width: 992px) {
  .desktopNav {
    height: 95px;
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .desktopNav {
    height: 85px;
    padding: 10px 15px;
    font-size: 20px;
    nav {
      a {
        margin: 5px;
      }
    }
  }
}
@media (max-width: 576px) {
  .desktopNav {
    display: none;
  }
  .mobileNav {
    display: block;
  }
}
